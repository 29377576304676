<template>
  <ion-page>
    <ion-router-outlet />
  </ion-page>
</template>

<script>
import { IonRouterOutlet, IonPage } from '@ionic/vue'

export default {
  name: 'InfoView',
  components: {
    IonRouterOutlet,
    IonPage
  }
}
</script>
